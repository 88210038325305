
export default class MasonryGrid {
    constructor(element, breakpoints, options = {}) {
        this.element = element;
        this.breakpoints = breakpoints;
        this.options = {
            useViewportWidth: false,
            balanceThreshold: 100, // Pixel difference threshold
            ...options
        };
        this.items = Array.from(this.element.children);
        this.columns = [];
        this.resizeObserver = new ResizeObserver(this.onResize.bind(this));
        this.resizeObserver.observe(this.element);
        this.layout();
    }

    getColumnCount() {
        const width = this.options.useViewportWidth ? window.innerWidth : this.element.offsetWidth;
        let lastColumnCount;
        for (const [breakpoint, columns] of Object.entries(this.breakpoints)) {
            if (width <= parseInt(breakpoint)) {
                return columns;
            }
            lastColumnCount = columns;
        }

        return this.breakpoints.default || lastColumnCount || 1;
    }

    createColumns(count) {
        this.element.innerHTML = '';
        this.columns = [];
        for (let i = 0; i < count; i++) {
            const column = document.createElement('div');
            column.className = 'masonry-column';
            this.element.appendChild(column);
            this.columns.push(column);
        }
    }

    distributeItems() {
        this.items.forEach((item, index) => {
            const columnIndex = index % this.columns.length;
            this.columns[columnIndex].appendChild(item.cloneNode(true));
        });
    }

    balanceColumns() {
        let balanced = false;
        while (!balanced) {
            const columnHeights = this.columns.map(col => col.scrollHeight);
            const maxHeight = Math.max(...columnHeights);
            const minHeight = Math.min(...columnHeights);

            if (maxHeight - minHeight > this.options.balanceThreshold) {
                const tallestColumnIndex = columnHeights.indexOf(maxHeight);
                const shortestColumnIndex = columnHeights.indexOf(minHeight);

                if (this.columns[tallestColumnIndex].children.length > 1) {
                    const lastItem = this.columns[tallestColumnIndex].lastElementChild;
                    this.columns[shortestColumnIndex].appendChild(lastItem);
                    balanced = true;

                } else {
                    balanced = true;
                }
            } else {
                balanced = true;
            }
        }
    }

    layout() {
        const columnCount = this.getColumnCount();
        this.element.style.setProperty('--columns', columnCount);
        this.createColumns(columnCount);
        this.distributeItems();

        // Add a small delay to ensure images are loaded before balancing
        setTimeout(() => {
            // this.balanceColumns();
        }, 100);
    }

    onResize() {
        this.layout();
    }

    destroy() {
        this.resizeObserver.disconnect();
    }
}

// Usage
/*
const masonryGrid = new MasonryGrid(document.getElementById('masonryGrid'), {
    '600': 1,
    '900': 2,
    '1200': 3,
    'default': 4
});
*/
